//==========  Extends  ==========//

// use these classes and placeholders with @extends
// classes defined here will be output in the final compiled css
// placeholders will not and are only used within sass
 
// note - extends will not work within media queries


//==========  Helpers and resets  ==========//

// set on .inner containers
%full-width { 
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 1.5rem;
}

// clear floats on elements after container
// prevents elements after from wrapping
%clear-fix {
    
    &:after {
        content: '';
        clear: both;
        display: block;
        width: 100%;
        overflow: hidden;
    }
}

// svg icon reset - use to hide any icons
%no-icon {
    min-height: 0;

    .icon-link {
        min-height: 0;
        padding-left: 0;
    }

    .icon {
        display: none;
    }
}

// reset for images, prevents whitespace around image
%image {
    display: block;
    width: 100%;
}

// default style for blockquotes
%blockquote {
    margin: 0;
    
    // left quote marks
    &:before {
        content: '\201C';
    }
    
    // right quote marks
    &:after {
        content: '\201D';
    }
    
    // reset for p tags that are put in by the CMS editor
    p {
        display: inline;
        margin: 0;
    }
}


// white arrows as controller for sliders
    %bx-controls {
        background: transparent;
        text-align:center;
        margin-top: $block-margin - 0.75;

        .bx-prev,
        .bx-next {
            position: absolute;
            top: 0;
            color: $color-white;
            font-size: 180px;
            line-height: 0.75;

            &:hover {
                color: $color-third;
            }
        }

        .bx-prev {
            left: -3rem;
        }
        .bx-next {
            right: -3rem;
        }
    }



// white circular pager icon for sliders
%bx-pager {
    .bx-pager-item {
        display: inline-block;
        margin: 0 25px 0 0;
    }

    .bx-pager-link {
        display: block;
        width: 16px;
        height: 16px;
        text-indent: -9999px;
        padding: 0;
        position: relative;
        border: $border-size $border-style $color-white;
        border-radius: 50%;
        margin-bottom: 5px;

        &:hover {
            background: $color-white;
        }

        &.active {
            background: $color-white;

            &:hover {
              background: $color-third-light;
              border: $border-size $border-style $color-third-light;
            }
        }
    }
}


// add as class to element or extend
.hidden {
    display: none;
}


//==========  Title styles  ==========//

// sub-titles
%sub-title {
    font-size: $font-size-body - 0.3;
    font-weight: $font-bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}
