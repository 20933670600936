
// Default .button class
.button {
    @extend %button;
    display: inline-block;
    padding: 0.5rem 3rem;
    transition: $transition;

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }

    &-secondary {
        background-color: $color-third;
    }
}
