
// Content styles
.centre .content {
	
	// Default paragraphs
	p {
		
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding-bottom: $paragraph-margin;
			// border-bottom: $border;
			margin-bottom: 0;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h4;

			@media #{$mobileXL} {
				font-size: $h3;
			}
		}
	}
	
	// New default <ul> lists
	* + ul {
		@extend %default-ul;
	}
	
	// New default <ol> lists
	* + ol {
		@extend %default-ol;
	}
}

